<template>
  <div
    class="container d-flex justify-content-center align-items-center default-layout"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "default",
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .default-layout {
    padding: 0 !important;
  }
  
}
</style>