import axios from "axios";

export const namespaced = true;

export const state = () => ({
  lastChatFlag: null,
});

export const getters = {};

export const actions = {
  consult({ commit }, consultBody) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_INTERNAL_SERVER_URL}/consult-expertMind`,
          consultBody
        )
        .then(({ data }) => {
          commit("setLastChatFlag", data?.token?.chatFlag);
          resolve(data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  speedToText({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_INTERNAL_SERVER_URL}/speech-to-text`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => rejected(e));
    });
  },
};
export const mutations = {
  setLastChatFlag(state, chatFlag) {
    state.lastChatFlag = chatFlag;
  },
};
