import axios from "axios";

export const namespaced = true;

export const state = () => ({
  isListening: false,
  isSpeaking: false,
  isProcessing: false,
  sessionInfo: null,
});

export const getters = {};

export const actions = {
  async newSession({ state }, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.new`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
          state.sessionInfo = data.data;
        })
        .catch((e) => rejected(e.response.data));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async listSession({}) {
    return new Promise((resolve, rejected) => {
      axios
        .get(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.list`, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e.response.data));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async interruptTask({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.interrupt`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
            },
          }
        )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async handleICE({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.ice`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async createTokenSession({}) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.create_token`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
            },
          }
        )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async startSession({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.start`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
            },
          }
        )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async repeat({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.task`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async stopSession({ state }, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.stop`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          state.sessionInfo = null;
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },
};
export const mutations = {
  resetState(state) {
    state.isListening = false;
    state.isSpeaking = false;
    state.isProcessing = false;
  },
  setIsListening(state, isListening) {
    state.isListening = isListening;
  },
  setIsSpeaking(state, isSpeaking) {
    state.isSpeaking = isSpeaking;
  },
  setIsProcessing(state, isProcessing) {
    state.isProcessing = isProcessing;
  },
};
