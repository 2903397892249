<template>
  <component :is="$route.meta.layout || 'default'">
    <router-view />
    <!-- Use the modal component -->
    <ModalComponent />
  </component>
</template>

<script>
import ModalComponent from "@/components/Common/Dialog.vue";

export default {
  name: "App",
  components: {
    ModalComponent,
  },
};
</script>

<style>
body {
  background-color: rgb(236, 236, 236);
}
</style>
